import React, { useEffect, useState } from "react";
import "./LeadsProceed.css";
import { AutoComplete, Popover, Select } from "antd";
import { FaSearch, FaTimes } from "react-icons/fa";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import ApiDomain from "../../../../api/ApiDomain";
import axios from "axios";

const LeadsProceedForm = ({ onSearch, Csv, param, fetchGarageListed }) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const { Option } = Select;
  const role = localStorage.getItem("role");
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const token = localStorage.getItem("admin");
  const [source, setSource] = useState([]);
  const [minimumToDate, setMinimumToDate] = useState(null);
  const handleInputChange = (value) => {
    const suggestions = source.filter(
      (garage) =>
        typeof garage.garageName === "string" &&
        garage?.garageName?.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(suggestions);
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
    const filterCriteria = {
      fromDate: "",
      toDate: "",
      status: "",
      search: "",
    };
    onSearch(filterCriteria);
  };

  const fetchGarageList = async (page) => {
    try {
      // fetchstart();
      setLoad(true);
      const response = await axios.get(
        `${ApiDomain.Domain}/garageNameList`,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data);
      if (response.status === 200) {
        setSource(response.data.data);
        // setCount(response.data.totalCount);
        setLoad(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchGarageList();
  }, []);

  const handleOptionClick = (option) => {
    const garageId = option.key;
    console.log(garageId); // Access the ID from the option object
    // param(garageId)
    fetchGarageListed(garageId);
    // Use the garageId as needed
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedFromDate(selectedDate);
    setDisableToDate(false);
    setMinimumToDate(selectedDate);
    const filterCriteria = {
      fromDate: selectedDate,
      toDate: selectedToDate,
      status: status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleToDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedToDate(selectedDate);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedDate,
      status: status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status: status,
      search: e.target.value,
    };
    onSearch(filterCriteria);
  };

  return (
    <div>
      <div className="inventory-manage-card-header">
        <form className="inventory-manage-form-control">
          <div className="inventory-manage-input">
            {/* <label htmlFor="fromdate" style={{ fontWeight: "bold" }}>
              From Date
            </label> */}
            <input
              type="date"
              id="fromdate"
              name="fromdate"
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder="From Date"
              className="inventory-manage-input-cal "
            />
          </div>
          <div className="inventory-manage-input">
            {/* <label htmlFor="todate" style={{ fontWeight: "bold" }}>
              To Date
            </label> */}
            <input
              type="date"
              id="todate"
              name="todate"
              value={selectedToDate}
              onChange={handleToDateChange}
              min={minimumToDate}
              placeholder="To Date"
              className="inventory-manage-input-cal"
              disabled={disableToDate}
              title={disableToDate ? "Please select From Date first" : ""}
            />
          </div>
          <div className="inventory-manage-input inventory-man1">
            {/* <label htmlFor="search" style={{ fontWeight: "bold" }}>
              Search
            </label> */}
            <input
              type="search"
              name="search"
              placeholder="Search..."
              className="search-input"
              onChange={handleSearchChange}
            />
          </div>

          <div>
            <Popover content={"Clear Filters"} trigger="hover">
              <button
                type="button"
                className="inventory-manage-form-button"
                onClick={handleClear}
              >
                <CloseOutlined />
              </button>
            </Popover>
          </div>
          {role === "admin" && (
            <div onClick={Csv}>
              <Popover content={"Export CSV"} trigger="hover">
                <button type="button" className="inventory-manage-form-excel">
                  <TbFileExport />
                </button>
              </Popover>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LeadsProceedForm;
