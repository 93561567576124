import React, { useEffect, useState } from "react";
import "../GarageManagement/Garage.css";
import {
  FaLock,
  FaLockOpen,
  FaPencilAlt,
  FaPlaneArrival,
  FaRegArrowAltCircleRight,
  FaUnlockAlt,
} from "react-icons/fa";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
// import EditCategory from "./EditCategory";
import moment from "moment";
import LeadForm from "./LeadForm";
import {
  Avatar,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Spin,
  message,
  Button,
} from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from "xlsx";
// import ViewServices from "./viewServices";
import MultipleImageUpload from "../../ApproveGarage/ImageUpload";
import TabsComponent from "../Subscriptions";
import SendToGarage from "./SendToGarage";

const LeadTable = ({ leadsList, load, fetchGarageList }) => {
  console.log(leadsList);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [active, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState("");
  const [data, setData] = useState([]);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [modalXV, setModalXV] = useState(false);
  const [modalXV1, setModalXV1] = useState(false);
  const [modalXV2, setModalXV2] = useState(false);
  const [modalXV3, setModalXV3] = useState(false);
  const [modalXV4, setModalXV4] = useState(false);
  const [modalXV8, setModalXV8] = useState(false);
  const [image, setImages] = useState("");
  const [social, setSocial] = useState(false);
  const [logoOptions, setLogoOptions] = useState([]);

  const [modalXV7, setModalXV7] = useState(false);
  const [modalXV9, setModalXV9] = useState(false);

  const [modalXV5, setModalXV5] = useState(false);

  const [modalXV6, setModalXV6] = useState(false);
  const [modalXV10, setModalXV10] = useState(false);

  const [form] = Form.useForm();
  const [category, setCategory] = useState([]);
  const [services, setServices] = useState([]);
  const { Option } = Select;
  const [initialSelectedServiceIds, setInitialSelectedServiceIds] = useState(
    []
  );
  const [initialCategoryNames, setInitialCategoryNames] = useState([]);
  const [initialValue, setInitialValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [operation, setOperation] = useState("+");
  const [result, setResult] = useState(0);
  const [selectedLeadId, setSelectedLeadId] = useState(null);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);

    // Try to parse the input as a number
    const num = parseFloat(inputValue);

    if (isNaN(num)) {
      // Handle invalid input
      console.log("Invalid input, not a number:", inputValue);
      setResult(null); // Or you can set it to a specific value, e.g., 0 or an error message
    } else {
      // Handle valid input
      setResult(num);
    }
  };

  console.log(result);

  const handleOperationChange = (value) => {
    setOperation(value);
  };
  const handleCalculate = () => {
    // Prepare the payload
    const payload = {
      garageId: selectedGarage.garageId,
      updateCoins: result,
      paymentType: "add", // Default value, can be overridden below
    };

    // Set paymentType based on the operation
    if (operation === "+") {
      payload.paymentType = "add"; // Use '=' instead of ':'
    } else if (operation === "-") {
      payload.paymentType = "sub"; // Use '=' instead of ':'
    } else {
      message.error("Invalid operation");
      return;
    }

    // Send the payload via an API call
    axios
      .put(`${ApiDomain.Domain}/updateCoins`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        message.success("Operation successfully submitted");
        setModalXV9(false);
        fetchGarageList();
      })
      .catch(() => {
        message.error("Failed to submit operation");
      });
  };

  const handleCalculateCancel = () => {
    setModalXV9(false);
  };

  const [garageIdData, setGarageIdData] = useState("");
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    search: "",
  });

  const fetchServiceLists = async (id) => {
    console.log(id);
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/category_service_list`,
        {
          categoryId: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data?.data);
      setServices(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchServiceLists();
  }, []);

  useEffect(() => {
    // Assuming selectedGarage is populated with data containing categories
    const categories = selectedGarage?.categories?.map(
      (category) => category.services
    );
    setInitialCategoryNames(categories);
  }, [selectedGarage]);

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, leadsList]);

  const applyFilters = () => {
    console.log(
      "filterCriteria == ",
      filterCriteria,
      moment("2024-12-20T18:31:34.617Z").format("YYYY-MM-DD")
    );
    const filteredList = leadsList
      .filter((item) => {
        // Standardize the date format for comparison
        const formattedDate = moment(
          moment(item?.createdAt).format("YYYY-MM-DD"),
          ["DD-MM-YYYY", "YYYY-MM-DD"],
          true
        );

        // Filtering logic based on fromDate, toDate, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          formattedDate.isSameOrAfter(
            moment(filterCriteria.fromDate, "YYYY-MM-DD"),
            "day"
          );

        const toDateMatch =
          !filterCriteria.toDate ||
          formattedDate.isSameOrBefore(
            moment(filterCriteria.toDate, "YYYY-MM-DD"),
            "day"
          );

        const searchMatch =
          !filterCriteria.search ||
          (item.customer.name &&
            item.customer.name
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.email &&
            item.email
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.address &&
            item.address
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.customer.phone &&
            item.customer.phone
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.vehicle.model &&
            item.vehicle.model
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.vehicle.brand &&
            item.vehicle.brand
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.vehicle.vehicleType &&
            item.vehicle.vehicleType
              .toString()
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.source &&
            item.source
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase()));

        return fromDateMatch && toDateMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };
  useEffect(() => {
    if (selectedGarage) {
      fetchServiceLists(selectedGarage?.categoryId);
    }
  }, [selectedGarage]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);
  console.log(visibleRows);
  const handleOpenModal = (index) => {
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  };
  const handleSubmit = (values) => {
    // Send socialMediaList data to your API endpoint
    console.log("Submitting data:", socialMediaList, social.garageId);
    const requestData = {
      garageId: social?.garageId,
      socialMedias: socialMediaList,

      // Make sure to define selectedCategories state
    };
    console.log(requestData);
    axios
      .put(`${ApiDomain.Domain}/update_SocialMedias`, requestData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("API Response:", response);
        console.log(response?.data?.responseCode);
        if (response.data?.responseCode === 200) {
          fetchGarageList();
          cancelx();
          message.success(response?.data?.message);
        } else if (response.data?.responseCode === 400) {
          message.warning(response.data?.message);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
    // Here, you can make an API call to submit the social media data
  };

  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
    form.resetFields(); // Reset form fields to their initial values

    setModalXV1(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  // Update initial selected service IDs when selectedGarage changes
  // useEffect(() => {
  //   if (selectedGarage) {
  //     const services = selectedGarage?.categories.map(category =>
  //       category?.services.map(service => service?.serviceName)
  //     ).flat();
  //     setInitialSelectedServiceIds(services);
  //   }
  // }, [selectedGarage]);

  console.log(initialSelectedServiceIds);
  const cancelx = () => {
    setModalXV(false);
    setModalXV1(false);
    setModalXV2(false);
    setModalXV3(false);
    setModalXV4(false);
    setModalXV5(false);
    setModalXV6(false);
    setModalXV7(false);
    setModalXV8(false);
    setModalXV9(false);
    setModalXV10(false);
    setSelectedImage(null);
  };

  async function handleSubmit2(values) {
    console.log(values);

    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/manageGarageProfile`,
        { garageId: id, ...values, status: active },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response?.data?.responseCode === 200) {
        fetchGarageList();
        message.success("Status Updated Successfully");
        handleCancel();
      }

      // Update the garage list after successful approval
    } catch (error) {
      console.log(error.message);
    }
  }

  const fetchGarageListSingle = async (id) => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/getListOfBankDetails`,
        {
          garageId: id ? id : "",

          // Use the current value of the 'page' state
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.status === 200) {
        console.log(response.data);

        setData(response.data.data);

        // setCount(response.data.totalCount);
        console.log(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleCancel = () => {
    setModalXV1(false);
    setModal(false);
    form.resetFields(); // Reset form fields to their initial values
  };
  console.log(leadsList.length);
  console.log(selectedGarage?.workingHours?.length === 0);

  const activeStatus = (item) => {
    setId(item.garageId);
    setActive(!item.status);
    setModal(true);
  };

  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item?.chooseDate).format("DD-MM-YYYY");
      return [
        index + 1,
        item.createdAt || "",

        item.customer.name || "",
        item.customer.email || "",
        item.customer.phone || "",
        item.customer.location || "",
        item.description || "",

        item.vehicle.brand || "",
        item.vehicle.model || "",

        item.vehicle.vehicleType
          ? item.vehicle.vehicleType === 1
            ? "4W"
            : "2W"
          : "",
        item.vehicle.fuelType || "",
        item.source || "",
      ];
    });

    // Add header row
    formattedData.unshift([
      "S.No",
      "Created Date",
      "Name",
      "Email",
      "Phone",
      "Address",
      "Description",
      "Brand",
      "Model",
      "Vehicle Type",
      "Fuel Type",
      "Source",
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
  };

  const a = selectedGarage?.categories?.map((category) =>
    category?.services?.map((service) => service.serviceName)
  );

  const socialmedia = (selectedGarage) => {
    setSocial(selectedGarage);
    setModalXV3(false);
    setModalXV6(true);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [socialMediaList, setSocialMediaList] = useState([
    { link: "", logo: "", socialMediaName: "" },
  ]);

  const handleAddSocialMedia = () => {
    setSocialMediaList([
      ...socialMediaList,
      { link: "", logo: "", socialMediaName: "" },
    ]);
  };

  const handleSocialMediaChange = (index, field, value) => {
    const updatedList = [...socialMediaList];
    updatedList[index][field] = value;
    setSocialMediaList(updatedList);
  };
  const handleSocialMediaChange1 = (index, field, value) => {
    const updatedList = [...socialMediaList];
    updatedList[index][field] = value;
    setSocialMediaList(updatedList);
  };

  const handleRemoveSocialMedia = (index) => {
    const updatedList = socialMediaList.filter((_, i) => i !== index);
    setSocialMediaList(updatedList);
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageHover = (image) => {
    setSelectedImage(image);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  console.log(image);

  return (
    <>
      {modal && (
        <Modal
          footer={null}
          centered
          visible={modal}
          onCancel={handleCancel}
          width={"auto"}
        >
          <div className="">
            <div className="add-garage-manage-modal-content-header">
              <div>
                <h3 style={{ color: "#b11226" }}>Garage Status</h3>
              </div>
            </div>
            <div className="add-category-modal-form">
              <Spin spinning={load}>
                <Form
                  // form={form}
                  layout="vertical"
                  style={{ minWidth: "600px" }}
                  onFinish={handleSubmit2}
                >
                  <div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "80%", marginTop: 10 }}>
                        <Col xl={24} lg={24}>
                          <Form.Item label="Reason : " name="reason">
                            <Input
                              placeholder="Enter Your Reason Name"
                              style={{}}
                            />
                          </Form.Item>
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div className="add-garage-manage-popup-footer">
                    <button
                      className="cancel-button"
                      onClick={handleCancel}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </button>

                    <button className="submit-button" htmlType="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </Modal>
      )}

      <Spin spinning={load}>
        <LeadForm onSearch={handleFilterChange} ExportCsv={handleExportExcel} />
        {!load && (
          <div className="garage-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th3">Created Date</th>

                  <th className="th5">Name</th>

                  <th className="th6">Email</th>
                  <th className="th5">Mobile Number</th>

                  <th className="th5">Address</th>
                  <th className="th5">Description</th>

                  <th className="th8">Vehicle Brand</th>
                  <th className="th9">Vehicle Model</th>
                  <th className="th5">Vehicle Type</th>
                  <th className="th5">Fuel Type</th>
                  <th className="th5">Source</th>

                  <th className="th11">Action</th>
                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Leads found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.createdAt).format(
                      "DD-MM-YYYY"
                    );
                    const lengthCategories = item?.categories?.length;
                    const lengthService = item?.services?.length;

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        <td>{formattedDate || "-"}</td>

                        <td>{item.customer.name || "-"}</td>
                        <td>{item.customer.email || "-"}</td>
                        <td>{item.customer.phone || "-"}</td>
                        <td>{item.customer.location || "-"}</td>

                        <td>{item.description || "-"}</td>
                        <td>{item.vehicle.brand || "N/A"}</td>
                        <td>{item.vehicle.model || "-"}</td>
                        <td>
                          {item.vehicle.vehicleType
                            ? item.vehicle.vehicleType === 1
                              ? "4W"
                              : "2W"
                            : "-"}
                        </td>
                        <td>{item.vehicle.fuelType || "-"}</td>
                        <td>{item.source || "-"}</td>
                        <td>
                          <div className="action-cell">
                            <div
                              className="edit-cell"
                              onClick={() => {
                                handleOpenModal(index);
                                setSelectedLeadId(item._id);
                              }}
                            >
                              <FaRegArrowAltCircleRight title="Send to garage" />
                            </div>
                          </div>
                        </td>

                        {/* <td>
                          <div className="action-cell">
                            <div
                              className="edit-cell"
                              onClick={() => activeStatus(item)}
                            >
                              {item.status ? <FaLockOpen /> : <FaLock />}
                            </div>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          simple
          onChange={handlePageChange}
          total={filteredGarageList.length}
          current={currentPage}
          style={{ marginTop: 10, float: "right" }}
        />
        {isModalVisible && (
          <SendToGarage
            handleCloseModal={() => setIsModalVisible(false)}
            leadId={selectedLeadId}
          />
        )}
      </Spin>
    </>
  );
};

export default LeadTable;
