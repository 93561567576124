import React, { useEffect, useState } from "react";
import "../../Inventory/Inventory.css";
import moment from "moment";
import {
  Avatar,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  message,
} from "antd";
import * as XLSX from "xlsx";
import LeadsProceedForm from "./LeadsProceedForm";
// import InventoryForm from "./Inventoryform";
const LeadsProceedTable = ({
  leadsList,
  load,
  fetchGarageList,
  count,
  param,
}) => {
  console.log(leadsList);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);
  const [id, setId] = useState("");
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, leadsList]);

  const applyFilters = () => {
    console.log(filterCriteria);
    const filteredList = leadsList
      ?.filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.date) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.date) <= new Date(filterCriteria.toDate);

        const searchMatch =
          !filterCriteria.search ||
          item.status
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase()) ||
          item.source
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase()) ||
          item.garageName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase()) ||
          item.serviceName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase()) ||
          item.customer.name
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase());

        return fromDateMatch && toDateMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.date) - new Date(a.date);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  // Calculate start and end index for visible rows
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  // Populate visibleRows array with data for the current page
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  console.log(selectedGarage);

  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item?.chooseDate).format("DD-MM-YYYY");
      return [
        index + 1,
        item.customer.name || "",
        item.customer.phone || "",
        item.customer.email || "",
        item.customer.location || "",
        item.vehicle.brand || "",
        item.vehicle.model || "",
        item.vehicle.fuelType || "",
        item.description || "",
        item.serviceName || "",
        item.garageName || "",
        item.garageCity || "",
        item.date || "",
        item.source || "",
        item.status || "",
        item.coinStatus ? "Clicked" : "Not Clicked",
        item.garageCoins || "",
      ];
    });

    // Add header row
    formattedData.unshift([
      "S.No",
      "Name",
      "Phone",
      "Email",
      "Address",
      "Vehicle Brand",
      "Vehicle Model",
      "Fuel Type",
      "Description",
      "Service Name",
      "Garage Name",
      "Garage City",
      "Date",
      "Source",
      "Lead Status",
      "Action",
      "Coins",
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
  };
  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };

  function cancel(e) {}

  console.log(count);
  console.log(filteredGarageList);

  return (
    <>
      <Spin spinning={load}>
        <LeadsProceedForm
          onSearch={handleFilterChange}
          Csv={handleExportExcel}
          param={param}
          fetchGarageListed={fetchGarageList}
        />
        {!load && (
          <div className="inventory-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Name</th>
                  <th className="th2">Phone</th>
                  <th className="th3">Email</th>
                  <th className="th5">Address</th>
                  <th className="th4">Brand</th>
                  <th className="th4">Model</th>
                  <th className="th4">Fuel Type</th>
                  <th className="th4">Description</th>
                  <th className="th5">Service Name </th>
                  <th className="th5">Garage Name</th>
                  <th className="th5">Garage City</th>
                  <th className="th4">Date</th>
                  <th className="th4">Source</th>
                  <th className="th7">Lead Status</th>
                  <th className="th7">Action</th>
                  <th className="th7">Coins</th>
                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Inventory List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.date).format(
                      "DD/MM/YYYY"
                    );

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        <td>{item.customer.name || "-"}</td>
                        <td>{item.customer.phone || "-"}</td>
                        <td>{item.customer.email || "-"}</td>
                        <td>{item.customer.location || "-"}</td>

                        <td>{item.vehicle.brand || "-"}</td>

                        <td>{item.vehicle.model || "-"}</td>
                        <td>{item.vehicle.fuelType || "-"}</td>
                        <td>{item.description || "-"}</td>

                        <td>{item.serviceName}</td>
                        <td>{item.garageName || "-"}</td>
                        <td>{item.garageCity || "-"}</td>

                        <td>{formattedDate}</td>

                        <td>{item.source}</td>

                        <td>{item.status}</td>
                        <td>{item.coinStatus ? "Clicked" : "Not Clicked"}</td>
                        <td>{item.garageCoins}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        )}

        <Pagination
          onChange={(page) => onChange(page)}
          simple
          total={filteredGarageList.length}
          pageSize={itemsPerPage}
          current={currentPage}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default LeadsProceedTable;
