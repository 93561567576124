import React, { useState, useEffect } from "react";
import "../GarageManagement/Garage.css";
import "react-datepicker/dist/react-datepicker.css";
import LeadTable from "./LeadTable";
import AddLead from "./AddLead";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";
import { Pagination } from "antd";

const LeadManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [leadsList, setLeadsList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load, setLoad] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("add-garage-manage-modal-overlay")) {
      handleCloseModal();
    }
  };

  const retryDelay = 1000; // in milliseconds

  const fetchGarageList = async (page = 1) => {
    setLoad(true);
    try {
      console.log("api calling...");
      const response = await axios.post(
        `${ApiDomain.Domain}/lead/getLeadDataList`,
        {
          // page: 2,
          // limit: 1,
          // startDate: "2024-01-12",
          // endDate: "2024-12-30",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("api calling... respne");

      if (response.status === 200) {
        console.log('loki ===== ', response.data.data.leadsWithDetails);
        setLeadsList(response.data.data.leadsWithDetails);
        // setCount(response.data.totalGarages);
        setLoad(false);
      }
    } catch (error) {
      console.log(error.response ? error.response.status : error.message);
      if (error.response && error.response.status === 503) {
        setTimeout(() => {
          fetchGarageList(page);
        }, retryDelay);
      } else {
        setLoad(false);
      }
    }
  };

  useEffect(() => {
    console.log("calling api in useeffect");
    fetchGarageList();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="garage-manage-container" onClick={handleOverlayClick}>
        <div className="garage-manage-content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="garage-manage-header">
              <h3 className="garage-manage-head">Lead Management</h3>
              <button className="add-garage-manage" onClick={handleOpenModal}>
                Add Lead
              </button>
            </div>
            <div style={{ marginTop: 10 }}>
              <ReloadOutlined
                style={{
                  marginLeft: "10px",
                  border: "1px solid black",
                  cursor: "pointer",
                  color: "black",
                  padding: 7,
                  borderRadius: 5,
                }}
                onClick={() => fetchGarageList(currentPage)}
              />
            </div>
          </div>
          <div className="garage-manage-card">
            <LeadTable
              leadsList={leadsList}
              load={load}
              handleCloseModal={handleCloseModal}
              fetchGarageList={() => fetchGarageList(currentPage)}
            />
          </div>
        </div>
        {isModalVisible && (
          <AddLead
            open={isModalVisible}
            handleCloseModal={handleCloseModal}
            fetchGarageList={() => fetchGarageList(currentPage)}
          />
        )}
      </div>
    </>
  );
};

export default LeadManagement;
