import React, { useState, useEffect } from "react";
import "react-clock/dist/Clock.css";

import {
  message,
  Form,
  Col,
  Row,
  Radio,
  Spin,
  Modal,
  Select,
  Button,
  Pagination,
} from "antd";
import "../GarageManagement/Garage.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";
import { MdClear } from "react-icons/md";

const { Option } = Select;

const SendToGarage = ({ handleCloseModal, fetchGarageList, open, leadId }) => {
  const [form] = Form.useForm();
  const [load, setLoad] = useState(false);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [garagesList, setGaragesList] = useState([]);
  const [visibleRows, setVisibleRows] = useState(
    garagesList.slice(startIndex, endIndex)
  );
  //   let visibleRows = garagesList.slice(startIndex, endIndex);
  const history = useHistory();
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [serviceList, setServiceList] = useState([]);

  const { Option } = Select;

  const token = localStorage.getItem("admin");

  const fetchCities = async (id) => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfCities/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCities(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchServiceStates = async () => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/listOfStates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data.data);
      setStates(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchServicesList = async () => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/adminServiceList`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setServiceList(response?.data?.data || []);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchServiceStates();
    fetchServicesList();
    form.submit();
  }, []);

  useEffect(() => {
    setVisibleRows(
      JSON.parse(JSON.stringify(garagesList.slice(startIndex, endIndex)))
    );
  }, [garagesList, startIndex, endIndex]);

  const handleSubmit = (values) => {
    // return console.log(values);
    form
      .validateFields()
      .then((formValues) => {
        const requestData = {
          ...formValues,
        };

        setLoad(true);

        axios
          .post(`${ApiDomain.Domain}/lead/searchGarage`, requestData, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.data?.responseCode === 200) {
              //   fetchGarageList();
              //   handleCloseModal();
              setGaragesList(response?.data?.data?.garages || []);
              message.success(response?.data?.message);
              setLoad(false);
            } else if (response.data?.responseCode === 400) {
              message.warning(response.data?.message);
              setLoad(false);
            }
          })
          .catch((error) => {
            console.error("API Error:", error);
          });
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleVehicleTypeChange = (e) => {
    setSelectedVehicleType(e.target.value);
    form.submit();
  };

  const handleCityChange = () => {
    form.submit();
  };

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const handleStateChange = (id) => {
    form.setFieldValue("city", null);
    form.setFieldValue("garageType", null);
    setSelectedState(id);
    fetchCities(id);
    form.submit();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectAll(false);
    setGaragesList((prev) => {
      prev.select = false;
      return [...prev];
    });
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const toggleAllCheckboxes = (e) => {
    setSelectAll(e.target.checked);
    setVisibleRows((prev) => {
      prev.forEach((item) => (item.select = e.target.checked));
      return [...prev];
    });
  };

  const handleCheckboxChange = (i, isChecked) => {
    setSelectAll(false);
    setVisibleRows((prev) => {
      console.log(prev[i].select, isChecked);
      prev[i].select = isChecked;
      const isAllSelected = prev.every((item) => item.select);
      setSelectAll(isAllSelected);
      return [...prev];
    });
  };

  const sendToGarageHandler = () => {
    const ids = visibleRows
      .filter((item) => item.select)
      .map((item) => item._id);
    setLoad(true);
    axios
      .post(
        `${ApiDomain.Domain}/lead/generateLeads`,
        {
          leadId,
          garages: ids,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("API Response:", response);
        console.log(response?.data?.responseCode);
        if (response.data?.responseCode === 201) {
          handleCloseModal();
          message.success(response?.data?.message);
          setLoad(false);
        } else if (response.data?.responseCode === 400) {
          message.warning(response.data?.message);
          setLoad(false);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("API Error:", error);
      });
  };

  return (
    <>
      <Modal
        open={true}
        centered
        onCancel={handleCloseModal}
        footer={null}
        width={"90%"} // Adjust the width as per your requirement
      >
        <div className="">
          <div className="">
            <div className="add-garage-manage-modal-content-header">
              <div>
                <h3
                  style={{
                    fontWeight: "bold",
                    color: "#b11226",
                    textAlign: "center",
                  }}
                >
                  Send To Garage
                </h3>
              </div>
            </div>
            <div
              className="add-garage-manage-modal-forms"
              style={{ maxHeight: "calc(100vh)", overflowY: "scroll" }}
            >
              <Spin spinning={load}>
                <div className="parent-select">
                  <Form
                    form={form}
                    layout="vertical"
                    style={{}}
                    onFinish={handleSubmit}
                  >
                    <div>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "20%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item label="State : " name="state">
                              <Select
                                showSearch
                                placeholder="Select State"
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleStateChange}
                              >
                                {/* Assume state is an array of state objects */}
                                {states?.map((suggestion) => (
                                  <Option
                                    key={suggestion._id}
                                    value={suggestion._id}
                                  >
                                    {suggestion.stateName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "20%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="City : "
                              name="city"
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select City"
                                style={{ width: "100%" }}
                                onChange={handleCityChange}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={!selectedState}
                              >
                                {cities?.map((city) => (
                                  <Option
                                    key={city._id} // Combine id and cityName for uniqueness
                                    value={city.cityName}
                                  >
                                    {city.cityName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "168px" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Vehicle Type : "
                              name="garageType"
                              required
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Radio.Group
                                name="radiogroup"
                                onChange={handleVehicleTypeChange}
                              >
                                <Radio value={1}>4W</Radio>
                                <Radio value={2}>2W</Radio>
                                {/* <Radio value={3}>Both</Radio> */}
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "20%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              name="serviceId"
                              label="Service Name"
                              rules={[
                                {
                                  required: false,
                                  message: "Please Select Service Name",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ height: 40 }}
                                placeholder="Service Name"
                                optionFilterProp="children"
                                onChange={() => form.submit()}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {serviceList.map((service) => (
                                  <Option key={service._id} value={service._id}>
                                    {service.serviceName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                        <div
                          className=""
                          style={{
                            marginTop: 24,
                            marginBottom: "auto",
                            width: "5%",
                          }}
                        >
                          <Col xl={24} lg={24}>
                            <button
                              className="submit-button"
                              onClick={() => {
                                setCities([]);
                                setSelectedCity(null);
                                form.resetFields();
                              }}
                              title="Clear Filters"
                            >
                              <MdClear />
                            </button>
                          </Col>
                        </div>
                      </Row>
                    </div>
                  </Form>
                </div>
                <div
                  className="garage-manage-table"
                  onClick={handleOverlayClick}
                >
                  <table>
                    <thead>
                      <tr>
                        <th className="th1">
                          <input
                            type="checkbox"
                            id="selectAll"
                            checked={selectAll}
                            onChange={toggleAllCheckboxes}
                          />
                        </th>
                        <th className="th1">S.No</th>
                        <th className="th3">Garage Name</th>
                        <th className="th5">Owner Name</th>
                        <th className="th5">Mobile Number</th>
                        <th className="th5">city</th>
                      </tr>
                    </thead>

                    <tbody>
                      {garagesList.length === 0 ? (
                        <tr>
                          <td colSpan="13" style={{ textAlign: "center" }}>
                            No garages found.
                          </td>
                        </tr>
                      ) : (
                        visibleRows.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  class="rowCheckbox"
                                  checked={item?.select}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                />
                              </td>
                              <td>{startIndex + index + 1}</td>
                              <td>{item.garageName || "-"}</td>
                              <td>{item.garageOwnerName || "-"}</td>
                              <td>{item.phoneNumber || "-"}</td>
                              <td>{item.city || "-"}</td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <Button
                  //   className="submit-button"
                  disabled={!visibleRows.some((item) => item.select)}
                  danger
                  style={{
                    color: "white",
                    background: "#b11226",
                    borderColor: "#b11226",
                  }}
                  onClick={sendToGarageHandler}
                >
                  Send
                </Button>
                <Pagination
                  simple
                  onChange={handlePageChange}
                  total={garagesList.length}
                  current={currentPage}
                  style={{ marginTop: 10, float: "right" }}
                />
              </Spin>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SendToGarage;
