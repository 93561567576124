import React, { useState, useEffect } from "react";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import {
  message,
  Upload,
  Form,
  Col,
  Row,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Radio,
  AutoComplete,
  Spin,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "../GarageManagement/Garage.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";
import LocationSearch from "../GarageManagement/Location";

const { Option } = Select;

const AddLead = ({ handleCloseModal, fetchGarageList, open }) => {
  const [form] = Form.useForm();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [garageList, setGarageList] = useState([]);
  const [load, setLoad] = useState(false);
  const [autoSuggestions, setAutoSuggestions] = useState([]);

  const [workingHours, setWorkingHours] = useState([
    { day: "Monday", slots: [], disabled: false },
    { day: "Tuesday", slots: [], disabled: false },
    { day: "Wednesday", slots: [], disabled: false },
    { day: "Thursday", slots: [], disabled: false },
    { day: "Friday", slots: [], disabled: false },
    { day: "Saturday", slots: [], disabled: false },
    { day: "Sunday", slots: [], disabled: false },
  ]);
  const history = useHistory();
  const [isGSTExist, setIsGSTExist] = useState(false);
  const [slot, setSlots] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const [lat, setLatitude] = useState("");
  const [long, setLongitude] = useState("");
  const { Option } = Select;
  const [addressed, setAddressed] = useState("");
  const [parentAddress, setParentAddress] = useState("");
  const [area, setArea] = useState("");
  const [services, setServices] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);

  const token = localStorage.getItem("admin");

  const fetchCategoryList = async () => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/adminServiceList`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGarageList(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchServiceList = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/ServiceNameAdminList`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setServiceList(response?.data?.services);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchServiceList();
  }, []);
  const fetchServiceStates = async () => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/listOfStates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data.data);
      setState(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const fetchCities = async (id) => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfCities/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data);
      setCity(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchServiceLists = async () => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/category_service_list`,
        {
          categoryId: null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setServices(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchBrands = async (vehicleType) => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/get_service_list`,
        {
          vehicleType,
        }
      );
      console.log("response.data ==== ", response.data);
      setBrands(Array.isArray(response?.data?.data) ? response.data.data : []);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const fetchModels = async (brand, vehicleType) => {
    try {
      const response = await axios.post(`${ApiDomain.Domain}/get_model_list`, {
        makeName: brand,
        vehicleType: vehicleType,
      });
      // setModels(response || []);
      setModels(Array.isArray(response?.data?.data) ? response.data.data : []);
    } catch (error) {
      console.error("Error fetching models:", error);
    }
  };

  useEffect(() => {
    fetchServiceStates();
    fetchServiceLists();
    fetchCategoryList();
  }, []);

  const handleSubmit = (values) => {
    // return console.log(values);
    form
      .validateFields()
      .then((formValues) => {
        const requestData = {
          ...formValues,
          customer: { ...formValues.customer, location: addressed },
        };
        console.log("requestData ==== ", requestData);

        setLoad(true);

        axios
          .post(`${ApiDomain.Domain}/lead/createLeadData`, requestData, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            console.log("API Response:", response);
            console.log(response?.data?.responseCode);
            if (response.data?.responseCode === 201) {
              fetchGarageList();
              handleCloseModal();
              message.success(response?.data?.message);
              setLoad(false);
            } else if (response.data?.responseCode === 400) {
              message.warning(response.data?.message);
              setLoad(false);
            }
          })
          .catch((error) => {
            console.error("API Error:", error);
          });
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    handleCloseModal();
  };
  const handleVehicleChange = (value) => {
    console.log(`Selected: ${value}`);
    // You can perform additional actions based on the selected value
  };
  useEffect(() => {
    filterGarageSuggestions();
  }, [selectedVehicleType, garageList]);

  const handleVehicleTypeChange = (e) => {
    setSelectedVehicleType(e.target.value);
    // filterGarageSuggestions();
    form.setFieldValue(["vehicle", "brand"], null);
    form.setFieldValue(["vehicle", "model"], null);
    fetchBrands(e.target.value);
  };

  const handleBrandChange = (value) => {
    form.setFieldValue(["vehicle", "model"], null);
    fetchModels(value, selectedVehicleType);
  };

  const filterGarageSuggestions = () => {
    const suggestions = garageList.filter(
      (garage) => garage.vehicleType == selectedVehicleType
    );
    console.log(suggestions);
    setFilteredGarageList(suggestions);
  };
  const handletimeChange = (e) => {
    setWorkingHours([
      { day: "Monday", slots: [], disabled: false },
      { day: "Tuesday", slots: [], disabled: false },
      { day: "Wednesday", slots: [], disabled: false },
      { day: "Thursday", slots: [], disabled: false },
      { day: "Friday", slots: [], disabled: false },
      { day: "Saturday", slots: [], disabled: false },
      { day: "Sunday", slots: [], disabled: false },
    ]);

    if (e.target.value === 2) {
      setSlots(false);
    } else {
      setSlots(true);
    }
  };
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  const validatePhoneNumber = (_, value) => {
    if (value && value.replace(/\D/g, "").length !== 10) {
      return Promise.reject("Phone number must be 10 digits");
    }
    return Promise.resolve();
  };
  const handleAddressChange = (autocompletedText, address) => {
    setParentAddress(autocompletedText);
    setAddressed(address);
  };
  const [selectedState, setSelectedState] = useState(true);
  const [selectedCity, setSelectedCity] = useState(null);

  const handleStateChange = (value) => {
    setSelectedState(false);
    // Fetch cities for the selected state from the API
    // Replace the API call with your actual API endpoint
    fetchCities(value);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  return (
    <>
      <Modal
        visible={open}
        centered
        onCancel={handleCloseModal}
        footer={null}
        width={800} // Adjust the width as per your requirement
      >
        <div className="">
          <div className="">
            <div className="add-garage-manage-modal-content-header">
              <div>
                <h3
                  style={{
                    fontWeight: "bold",
                    color: "#b11226",
                    textAlign: "center",
                  }}
                >
                  Add Lead
                </h3>
              </div>
            </div>
            <div
              className="add-garage-manage-modal-forms"
              style={{ maxHeight: "500px", overflowY: "scroll" }}
            >
              <Spin spinning={load}>
                <div className="parent-select">
                  <Form
                    form={form}
                    layout="vertical"
                    style={{}}
                    onFinish={handleSubmit}
                  >
                    <div>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Name : "
                              name={["customer", "name"]}
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                {
                                  required: true,
                                  message: "Please input your Lead Name!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Your Lead Name"
                                style={{}}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Contact No : "
                              name={["customer", "phone"]}
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                {
                                  required: true,
                                  message: "Please input your Phone number!",
                                },
                                {
                                  pattern: /^[0-9]{10}$/,
                                  message: "Phone number must be 10 digits!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Mobile Number"
                                style={{}}
                                maxLength={10}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Email : "
                              name={["customer", "email"]}
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Your Email"
                                style={{}}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              name={["customer", "location"]}
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                // {
                                //   required: true,
                                //   message: "Please select address!",
                                // },
                              ]}
                            >
                              <LocationSearch
                                address={addressed}
                                updateValue={setAddressed}
                                setLatitude={setLatitude}
                                setLongitude={setLongitude}
                                onLocationChange={handleAddressChange}
                                area={setArea}
                                initialValue={parentAddress}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <div className="add-garage-manage-modal-content-header">
                        <div>
                          <h5
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: 17,
                            }}
                          >
                            Vehicle Information
                          </h5>
                        </div>
                      </div>
                      <Row>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Vehicle Type : "
                              name={["vehicle", "vehicleType"]}
                              required
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                {
                                  required: true,
                                  message: "Please input your vehicle Type!",
                                },
                              ]}
                            >
                              <Radio.Group
                                name="radiogroup"
                                onChange={handleVehicleTypeChange}
                              >
                                <Radio value={1}>4W</Radio>
                                <Radio value={2}>2W</Radio>
                                {/* <Radio value={3}>Both</Radio> */}
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Brand : "
                              name={["vehicle", "brand"]}
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ height: 40 }}
                                placeholder="Brand"
                                optionFilterProp="children"
                                onChange={handleBrandChange}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {brands.map((brand) => (
                                  <Option key={brand.name} value={brand.name}>
                                    {brand.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <Row>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Model : "
                              name={["vehicle", "model"]}
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ height: 40 }}
                                placeholder="Model"
                                optionFilterProp="children"
                                // onChange={handleBrandChange}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {models.map((model) => (
                                  <Option key={model.name} value={model.name}>
                                    {model.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Fuel Type : "
                              name={["vehicle", "fuelType"]}
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{}}
                                placeholder="Select Fuel Type"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="Petrol">Petrol</Option>
                                <Option value="Diesel">Diesel</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <div className="add-garage-manage-modal-content-header">
                        <div>
                          <h5
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: 17,
                            }}
                          >
                            Service Information
                          </h5>
                        </div>
                      </div>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              name="service"
                              label="Service Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Service Name",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ height: 40 }}
                                placeholder="Service Type"
                                optionFilterProp="children"
                                // onChange={handleChange}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {garageList.map((garage) => (
                                  <Option key={garage._id} value={garage._id}>
                                    {garage.serviceName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }} className="">
                          <Col xl={24} lg={24}>
                            <Form.Item
                              name="source"
                              label="Source "
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Source",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{}}
                                placeholder="Select Type"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="Direct Call">Direct Call</Option>
                                <Option value="View Garage">View Garage</Option>
                                <Option value="Service Search">
                                  Service Search
                                </Option>
                                <Option value="Request Quote">
                                  Request Quote
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Description : "
                              name="description"
                            >
                              <TextArea
                                rows={3}
                                placeholder="Enter Description"
                              />
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                    </div>
                    <div className="add-garage-manage-popup-footer">
                      <button
                        className="cancel-button"
                        onClick={() => handleCancel()}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </button>

                      <button className="submit-button" htmlType="submit">
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddLead;
